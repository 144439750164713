import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'tag-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new tag'))+" ")]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'tag-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'tag-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('tag-action-delete',{attrs:{"icon":"","value":item},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Tags') },false))}
var staticRenderFns = []

export { render, staticRenderFns }